import 'plyr/src/sass/plyr.scss';

import {Behaviour} from "../../../global/scripts/behaviour";

const videoBehaviour: Behaviour = (() => {
    const attach = function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.video').forEach(initVideo);
    };

    const initVideo = function (element: HTMLElement) {
        import(/* webpackChunkName: "video" */ "plyr").then(module => {
            const Plyr = module.default;
            const hero = element.closest('.hero');

            const autoplay = element.getAttribute('data-autoplay') === 'true';
            const background = element.getAttribute('data-background') === 'true';


            let playerConfig: any = {
                clickToPlay: !autoplay
            };

            if (background) {
                playerConfig = {
                    ...playerConfig,
                    controls: [],
                    fullscreen: {
                        enabled: false,
                        fallback: false
                    },
                    vimeo: {
                        background: true
                    },
                    loop: {
                        active: true
                    }
                };
            }

            const player = new Plyr(element, playerConfig);

            if (autoplay) {
                player.on('ready', () => {
                    player.muted = true;
                    player.play();
                });
            }

            if (hero) {
                player.on('playing', () => {
                    hero.classList.add('hero--videoStarted');
                });
            }
        });
    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(videoBehaviour);

