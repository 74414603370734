import {createLocationMap} from "./src/createLocationsMap";

const blockLocationsBehaviour = (function () {

    const attach = () => {

        const block = document.querySelector<HTMLElement>('.blockLocations');

        if (!block) {
            return;
        }

        const locationsEndpoint = block.getAttribute('data-locations-endpoint');
        const googleMapsUrl = block.getAttribute('data-google-maps-url');
        const mapElement = block.querySelector<HTMLElement>('.blockLocations__map');
        const formElement = block.querySelector<HTMLFormElement>('form');
        const locationListElement = block.querySelector<HTMLElement>('.blockLocations__locationsList');
        const loaders = block.querySelectorAll<HTMLElement>('.loadingIndicator');

        if (!locationsEndpoint) {
            return;
        }

        if (!mapElement) {
            return;
        }

        if (!locationListElement) {
            console.log('No location list dom element found');
            return;
        }

        if (!googleMapsUrl) {
            console.log('No google maps url found');
            return;
        }

        if (!formElement) {
            console.log('No form found');
            return;
        }

        if (loaders.length === 0) {
            console.log('No loaders found');
            return;
        }

        addScript(googleMapsUrl, () => {

            createLocationMap({
                mapHtmlElement: mapElement,
                locationsListElement: locationListElement,
                endpoint: locationsEndpoint,
                formElement: formElement,
                markerImage: '/app/themes/houtolie/assets/images/marker.png',
                loaders: loaders,
            });
        });
    };


    const addScript = (url: string, callback: () => void) => {
        var script = document.createElement('script');
        if (callback) script.onload = callback;
        script.type = 'text/javascript';
        script.src = `${url}&callback=console.log`;
        document.body.appendChild(script);
    }


    return {
        attach: attach,
    }
})();

behaviours.addBehaviour(blockLocationsBehaviour);
