import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper, {Navigation, Pagination, Thumbs} from 'swiper';

const imageSlider: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.imageSlider');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block: HTMLElement) => {
            init(block);
        });
    };

    const init = function (block: HTMLElement) {
        const baseClass = 'imageSlider';
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;

        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);
        const navigationSliderElement = block.querySelector<HTMLElement>(`.${baseClass}__navigationSlider`);
        let navigationSlider = null;

        if (!sliderElement) {
            return false;
        }

        if (navigationSliderElement) {
            navigationSlider = new Swiper(navigationSliderElement, {
                wrapperClass: wrapperClass,
                slideClass: slideClass,
                freeMode: true,
                speed: 300,
                spaceBetween: 20,
                slidesPerView: 4,
            });
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation, Thumbs],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 0,
            thumbs: {swiper: navigationSlider},
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(imageSlider);
