import {Behaviour} from "../../../global/scripts/behaviour";

const jsLinkBehaviour: Behaviour = (() => {

    const attach = function (context: HTMLElement) {
        const links = context.querySelectorAll<HTMLElement>('.jsLink');

        if (links.length === 0) {
            return;
        }

        links.forEach(link => init(link));
    };

    const init = function (link: HTMLElement) {
        const href = link.getAttribute('data-href');
        const target = link.getAttribute('data-target') || '_self';

        if (!href) {
            return;
        }

        link.addEventListener('click', () => {
            if (target && target == '_blank') {
                window.open(href);
            } else {
                const location = new Location();
                location.href = href;
                window.location = location
            }
        });
    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(jsLinkBehaviour);
