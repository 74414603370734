import { Behaviour } from '../../../global/scripts/behaviour';
import {variations} from "../../organism/product-hero/src/varations";
import {quantity} from "../../organism/product-hero/src/quantity";

const teaserProductBehaviour : Behaviour = (() => {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.teaserProduct');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block, index) => init(block, index));

        document.addEventListener('cart-success-closed', (e) => {
              context.querySelector('.teaserProduct--active')?.classList.remove('teaserProduct--active');
        });
    };

    const init = function (block: HTMLElement, index: number) {
        block.querySelectorAll<HTMLElement>('.teaserProduct__trigger, .teaserProduct__close').forEach(trigger => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();
                block.classList.toggle('teaserProduct--active');
                document.dispatchEvent(new CustomEvent('teaser-add-to-cart-opened'));
                closeOtherBlocks(block);
            });
        });
        variations.attach(block);
        quantity.attach(block);
    };

    const closeOtherBlocks = function (block: HTMLElement) {
        const otherBlocks = document.querySelectorAll<HTMLElement>('.teaserProduct--active');

        if (otherBlocks.length === 0) {
            return;
        }

        otherBlocks.forEach(otherBlock => {
            if (otherBlock !== block) {
                otherBlock.classList.remove('teaserProduct--active');
            }
        });
    }

    return {
        attach: attach
    }
})();

jQuery(document).on('sf:ajaxfinish', function () {
    teaserProductBehaviour.attach(document.documentElement);
});

behaviours.addBehaviour(teaserProductBehaviour);
