behaviours.addBehaviour(
    (() => {
        const attach = function (context: HTMLElement) {
            context.querySelectorAll('div[data-framed=true]').forEach((block) => {
                const nextBlock = block.nextElementSibling;
                if (nextBlock?.getAttribute('data-framed') === 'true') {
                    block.classList.add('blockText--nextSame');
                }
            });

            context.querySelectorAll('div[data-framed=false]').forEach((block) => {
                const nextBlock = block.nextElementSibling;
                if (nextBlock?.getAttribute('data-framed') === 'false') {
                    block.classList.add('blockText--nextSame');
                }
            });
        };

        return {
            attach: attach,
        };
    })(),
);
