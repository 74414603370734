import Accordion from 'accordion-js';

const accordionBehaviour= (() => {
    const attach = (context) => {
        const baseClass = 'accordion';
        context.querySelectorAll(`.${baseClass}`).forEach(element => {
            new Accordion(element, {
                ariaEnabled: false,
                showMultiple: false,
                elementClass: `${baseClass}__item`,
                triggerClass: `${baseClass}__itemTrigger`,
                panelClass: `${baseClass}__itemPanel`,
                activeClass: `${baseClass}__item--isActive`,
            });
        });
    };

    return {
        attach,
    };

})();

behaviours.addBehaviour(accordionBehaviour);
