const CookieNoticeBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('#cookie-notice');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block) => init(block));
    };

    const init = function (block: HTMLElement) {
        const title = block.querySelector('h4');

        const textContainer = document.getElementById('cn-notice-text');
        const moreInfoButton = document.getElementById('cn-more-info');

        if (textContainer && title) {
            textContainer.prepend(title);
        }

        if (textContainer && moreInfoButton) {
            moreInfoButton.className = '';
            textContainer.append(' ');
            textContainer.append(moreInfoButton);
        }
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(CookieNoticeBehaviour);
