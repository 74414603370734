import {LocationDto} from "./locationsSchema";

const locationsTemplate = require("../../../molecule/locations/locations.hbs");

export interface listInput {
    locationListElement: HTMLElement
    onClickListItem?: (locationId: string) => void
}

export const createList = ({locationListElement, onClickListItem}: listInput) => {

    const addItemClickHandler = () => {
        locationListElement.querySelectorAll<HTMLElement>('.locations__item').forEach((location) => {
            const locationId = location.getAttribute('data-id');
            if (locationId && onClickListItem) {
                location.addEventListener('click', () => {
                    onClickListItem(locationId);
                });
            }
        });
    }

    const updateList = (locations: LocationDto[]) => {
        locationListElement.innerHTML = locationsTemplate({
            locations: locations,
        });

        addItemClickHandler();
    }

    return {
        updateList,
    }
}
