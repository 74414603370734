behaviours.addBehaviour(
    (() => {
        const attach = function (context: HTMLElement) {
            context.querySelectorAll<HTMLAnchorElement>('.text__moreTrigger').forEach((trigger) => {
                const moreWrapper = trigger.closest('[data-background]')?.querySelector('.text__more');
                trigger.addEventListener('click', function (e) {
                    const textShow = trigger.getAttribute('data-show') || 'Tonen';
                    const textHide = trigger.getAttribute('data-hide') || 'Verbergen';
                    if (moreWrapper && jQuery(moreWrapper).is(':visible')) {
                        jQuery(moreWrapper).slideUp();
                        trigger.innerHTML = textShow;
                    } else if (moreWrapper) {
                        jQuery(moreWrapper).slideDown();
                        trigger.innerHTML = textHide;

                    }
                });
            });
        };

        return {
            attach: attach,
        };
    })(),
);
