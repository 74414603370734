import {array, InferType, number, object, string} from "yup";

export const LocationCoordinatesDtoSchema = object({
    lat: number().required(),
    lng: number().required(),
}).label('Location Coordinates Dto');

export const LocationDtoSchema = object({
    id: string().required(),
    title: string().required(),
    coordinates: LocationCoordinatesDtoSchema,
    address: string().required(),
    distance: string().required(),
}).label('Location Dto Schema');

export const LocationsDtoSchema = object({
    locations: array(LocationDtoSchema).min(0).required(),
    center: LocationCoordinatesDtoSchema,
}).label('Locations Dto Schema').required();

export type LocationCoordinatesDto = InferType<typeof LocationCoordinatesDtoSchema>;
export type LocationDto = InferType<typeof LocationDtoSchema>;
export type LocationsDto = InferType<typeof LocationsDtoSchema>;
