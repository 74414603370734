import { Behaviour } from './behaviour';

/**
 * Scroll behaviour
 */
const debugBehaviour: () => Behaviour = () => {
    const attach = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        if (urlSearchParams.has('debug')) {
            window.localStorage.setItem('debug', urlSearchParams.get('debug') || '0');
        }
        const debugEnabled = window.localStorage.getItem('debug') === '1';
        document.documentElement.classList.toggle('debug', debugEnabled);

    };

    return {
        attach
    }
};

behaviours.addBehaviour(debugBehaviour());
