import {LocationCoordinatesDto, LocationDto} from "./locationsSchema";

interface createMarkersInput {
    map: google.maps.Map

    markerImage: string
}

export const createMarkers = ({map, markerImage}: createMarkersInput) => {

    let markersOnMap: { [key: string]: google.maps.Marker } = {};
    let activePopup: google.maps.InfoWindow | null = null;
    let defaultCenter = new google.maps.LatLng(0, 0);
    let defaultRadius = 0;

    const updateMarkersForLocations = (locations: LocationDto[]) => {

        const bounds = new google.maps.LatLngBounds();

        //Remove all markers
        Object.keys(markersOnMap).forEach((markerId) => {
            markersOnMap[markerId].setMap(null);
            delete markersOnMap[markerId];
        });

        console.log(locations);

        locations.forEach((location) => {
            const locationCoordinates = new google.maps.LatLng(location.coordinates.lat, location.coordinates.lng);
            const marker = new google.maps.Marker({
                position: locationCoordinates,
                map: map,
                title: location.title,
                icon: {
                    url: markerImage,
                    scaledSize: new google.maps.Size(24, 35),
                }
            });

            const popup = new google.maps.InfoWindow({
                content: `<div class="infoPopup">
                    <h2>${location.title}</h2>
                    <p>${location.address}</p>
                    <a target="_blank" href="https://www.google.nl/maps/dir//${location.address.toString().replaceAll('<br/>', '+')}">Routebeschrijving</a>
                    ${location.website ? `<br/><a target="_blank" href="${location.website}">Website</a>` : ''}
                </div>`,

            });

            marker.addListener("click", () => {
                activePopup?.close();
                popup.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
                activePopup = popup;
            });

            markersOnMap[location.id] = marker;
            bounds.extend(locationCoordinates);
        });

        if (locations.length === 0) {
            map.setCenter(defaultCenter);
            map.setZoom(12);
        } else if (locations.length === 1) {
            map.setCenter(locations[0].coordinates);
            map.setZoom(12);
        } else {
            map.fitBounds(bounds);
        }
    }

    const activateMarker = (locationId: string) => {
        const marker = markersOnMap[locationId];
        if (marker) {
            map.panTo(marker.getPosition() as google.maps.LatLng);
            map.setZoom(11);
            google.maps.event.trigger(marker, 'click');
        }
    }

    const setCenter = (center: LocationCoordinatesDto) => {
        defaultCenter = new google.maps.LatLng(center.lat, center.lng);
    }

    const setRadius = (radius: number) => {
        defaultRadius = radius;
    }

    return {
        setCenter,
        setRadius,
        updateMarkersForLocations,
        activateMarker,
    }
}
