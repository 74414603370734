import { Behaviour } from '../../../global/scripts/behaviour';

export interface MobileMenuBehaviour extends Behaviour {
    reset: (menu: HTMLElement) => void;
}

const MobileMenuBehaviour: MobileMenuBehaviour = (() => {
    const baseClass = 'mobileMenu';

    const attach = function (context: HTMLElement) {
        const menu = context.querySelector(`.${baseClass}`) as HTMLElement;
        const menuContent = context.querySelector(`.${baseClass}__content`) as HTMLElement;

        // No menu found, so exit
        if (!(menu && menuContent)) {
            return false;
        }

        // Behaviour already attached, so exit
        if (menu.classList.contains(`${baseClass}--behaviourAttached`)) {
            return false;
        }

        menu.classList.add(`${baseClass}--behaviourAttached`);

        init(menu, menuContent);

        document.addEventListener('mobileDrawerOpened', () => {
            reset(menu);
        });
    };

    const getParentUls = (element: HTMLElement) => {
        let parents = [];
        let parent = element.parentNode;

        while (parent !== document) {
            const o = parent;
            if (o!.nodeName === 'UL') {
                parents.push(o);
            }
            parent = o!.parentNode;
        }
        return parents;
    };


    const init = (menu: HTMLElement, menuContent: HTMLElement) => {
        let resizeTimer: ReturnType<typeof setTimeout> | null = null;


        // Goto submenu trigger
        const submenuTriggers = menu.querySelectorAll(`.${baseClass}__submenuTrigger`) as NodeListOf<HTMLElement>;
        submenuTriggers.forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();

                const target = event.target as HTMLElement | null;

                if (!target) {
                    return;
                }

                const ul = target.closest('li')?.querySelector(':scope > ul');
                if (!ul) {
                    return;
                }

                menu.setAttribute('data-niveau', getParentUls(element).length.toString());

                ul.classList.add('show');

                menuContent.style.height = `${ul.clientHeight}px`;
            });
        });

        const getDataNiveau: () => number = () => {
            const value: string | null = menu.getAttribute('data-niveau');

            if (!value) {
                return 0;
            }

            return parseInt(value);
        };

        // Go back trigger
        menu.querySelectorAll(`.${baseClass}__backlink`).forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault();

                const activeNiveau: number = getDataNiveau();
                const parent = element.parentElement as HTMLElement;

                menu.setAttribute('data-niveau', (activeNiveau - 1).toString());
                menuContent.style.height = 'auto';

                setTimeout(
                    function () {
                        parent.classList.remove('show');
                    }.bind(this),
                    300,
                );
            });
        });

        window.addEventListener('resize', () => {
            if (resizeTimer) {
                clearTimeout(resizeTimer);
            }
        });
    };

    const reset = function (menu: HTMLElement) {
        menu.querySelectorAll('ul').forEach((element) => {
            element.classList.remove('show');
        });
        menu.setAttribute('data-niveau', '0');
    };

    return {
        attach: attach,
        reset: reset,
    };
})();

behaviours.addBehaviour(MobileMenuBehaviour);
