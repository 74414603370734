
behaviours.addBehaviour((function () {

    const attach = function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.styledButton').forEach(button => {
            // Enable smooth scrolling
            const buttonHref = button.getAttribute('href');
            if (buttonHref && buttonHref.length > 1 && buttonHref.startsWith('#')) {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    const target = document.querySelector(buttonHref);
                    if (target) {
                        target.scrollIntoView({behavior: 'smooth'});
                    }
                });
            }
        });
    };

    return {
        attach: attach
    }
})());
