import webkitLineClamp from 'webkit-line-clamp';
import {Behaviour} from "../../../global/scripts/behaviour";


const teaserArticleBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.teaserArticle__text').forEach((element) => {
            webkitLineClamp(element, 6);
        });

        context.querySelectorAll<HTMLElement>('.teaserArticle--withImage .teaserArticle__title').forEach((element) => {
            webkitLineClamp(element, 2);
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(teaserArticleBehaviour);
