import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper, {Navigation, Pagination} from 'swiper';

const baseBlockSliderBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.baseSliderBlock');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block: HTMLElement) => {
            init(block);
        });
    };

    const init = function (block: HTMLElement) {
        const baseClass = 'baseSliderBlock';
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;
        const navClass = 'slideNavigation__item';
        const paginationBulletClass = `${baseClass}__paginationBullet`;
        const paginationBulletClassActive = `${baseClass}__paginationBullet--active`;

        const slidesPerView = parseInt(block.getAttribute('data-slides-per-view') || '3');
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`);
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--prev`);
        const footer = block?.querySelector<HTMLElement>(`.${baseClass}__footer`);
        const pagination = block?.querySelector<HTMLElement>(`.${baseClass}__pagination`);

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation, Pagination],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 15,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                bulletClass: paginationBulletClass,
                bulletActiveClass: paginationBulletClassActive,
                clickable: true,
            },
            breakpoints: {
                1200: {
                    slidesPerView: slidesPerView,
                },
                1024: {
                    spaceBetween: 23,
                    slidesPerView: slidesPerView > 3 ? 3 : slidesPerView
                },
                768: {
                    spaceBetween: 23,
                    slidesPerView: 2
                }
            },
            on: {
                breakpoint: function () {
                    setTimeout(() => {
                        if (footer) {
                            const navigationHidden = block.querySelectorAll(`.${navClass}--lock`).length == 2;
                            footer.classList.toggle(`${baseClass}__footer--hidden`, navigationHidden);
                        }
                    }, 1);
                }
            }
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(baseBlockSliderBehaviour);
