import { Behaviour } from '../../../global/scripts/behaviour';
import Swiper, { Navigation } from 'swiper';

const blockImageLinksBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockImageLinks');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block: HTMLElement) => {
            init(block);
        });
    };

    const init = function (block: HTMLElement) {
        const baseClass = 'blockImageLinks';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;
        const navClass = 'slideNavigation__item';
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`);
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--prev`);

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 20,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                1000: {
                    spaceBetween: 30,
                },
                1300: {
                    slidesPerView: 6,
                },
            },
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(blockImageLinksBehaviour);
