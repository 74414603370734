import { Behaviour } from '../../../global/scripts/behaviour';
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export interface AddToCartResponse {
    items_count: number;
}

const addToCartFormBehaviour : Behaviour = (() => {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLFormElement>('.addToCartForm');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block));

        document.addEventListener('teaser-add-to-cart-opened', (e) => {
            const successTeaser = context.querySelector('.teaserProduct--success');

            if (!successTeaser) {
                return;
            }

            successTeaser.classList.remove('teaserProduct--success');
            const successMessage = successTeaser.querySelector('.addToCartSuccess');
            successMessage?.remove();
        });
    }

    const init = function (form: HTMLFormElement) {
        const button = form.querySelector<HTMLButtonElement>('.addToCartForm__button');
        const submitUrl = form.getAttribute('action');
        const nonce = form.getAttribute('data-nonce');

        if (!button || !submitUrl || !nonce) {
            return;
        }

        form.addEventListener('submit', (event) => {
            event.preventDefault();
            button.disabled = true;

            const formData = new FormData(form);
            const config: AxiosRequestConfig = {
                headers: {
                    'X-WC-Store-API-Nonce': nonce
                }
            };

            axios.postForm(submitUrl, formData, config).then(function (response: AxiosResponse<AddToCartResponse>) {
                handleSuccess(form)
                document.dispatchEvent(new CustomEvent('update-cart-count', {detail: response.data.items_count}));
                button.disabled = false;
            }).catch(function (error) {
                button.disabled = false;
            });
        });
    };

    const handleSuccess = function (form: HTMLFormElement) {
        const addToCartSuccess = document.querySelector('#add-to-cart-success-template .addToCartSuccess');
        const teaser = form.closest('.teaserProduct');
        const successRegion = teaser && teaser.querySelector('.teaserProduct__success');

        if (!addToCartSuccess || !successRegion) {
            return;
        }

        const message = addToCartSuccess.cloneNode(true) as HTMLElement;

        const messageTitleElement = message.querySelector<HTMLDivElement>('.addToCartSuccess__productTitle');
        const messageVariationElement = message.querySelector('.addToCartSuccess__productVariation');

        const productTeaser = teaser.querySelector('.teaserProduct__title')?.textContent;
        const teaserSelect = teaser.querySelector<HTMLSelectElement>('select');

        if (messageTitleElement && productTeaser) {
            messageTitleElement.innerHTML = productTeaser;
        } else if (messageTitleElement) {
            messageTitleElement.remove();
        }

        if (messageVariationElement && teaserSelect) {
            const selectedIndex = teaserSelect.selectedIndex;
            const options = teaserSelect.options;
            messageVariationElement.innerHTML = options[selectedIndex].text;
        } else if (messageVariationElement) {
            messageVariationElement.remove();
        }

        successRegion.appendChild(message);

        teaser.classList.add('teaserProduct--success');

        const closeButton = message.querySelector('.addToCartSuccess__close');
        closeButton?.addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('cart-success-closed', {detail: 0}));
            teaser.classList.remove('teaserProduct--success');
            message.remove();
        });

        const continueButton = message.querySelector('.addToCartSuccess__continueButton');
        continueButton?.addEventListener('click', (e) => {
            e.preventDefault();
            document.dispatchEvent(new CustomEvent('cart-success-closed', {detail: 0}));
            teaser.classList.remove('teaserProduct--success');
            message.remove();
        });
    }

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(addToCartFormBehaviour);
