import docReady from './docReady';

/* eslint-disable no-var */
declare global {
    var behaviours: Behaviours;
    var wp: any;
}

export interface Behaviour {
    attach: (context: HTMLElement) => void;
}

export interface Behaviours {
    addBehaviour: (behaviour: Behaviour) => void;
    attachBehaviours: (context: HTMLElement) => void;
}

export const Behaviours = () => {
    const behaviours: Behaviour[] = [];

    const addBehaviour: (behaviour: Behaviour) => void = (behaviour) => {
        behaviours.push(behaviour);
    };

    const attachBehaviours: (context: HTMLElement) => void = (context) => {
        behaviours.forEach((behaviour) => {
            if (typeof behaviour.attach === 'function') {
                behaviour.attach(context);
            }
        });
    };

    return {
        addBehaviour,
        attachBehaviours,
    };
};

global.behaviours = Behaviours();

// Attach behaviours when all dom elements are loaded
docReady(function () {
    const rootContext = document.documentElement;
    behaviours.attachBehaviours(rootContext);
});

if (typeof wp !== 'undefined' && typeof wp.data !== 'undefined') {
    wp.data.subscribe(function () {
        const blocks = document.documentElement.querySelectorAll('.acf-block-preview') as NodeListOf<HTMLElement>;
        blocks.forEach(function (block) {
            const blockFirstChild = block.querySelector<HTMLElement>(':scope > div');
            if (blockFirstChild && !blockFirstChild.classList.contains('viv-loaded')) {
                behaviours.attachBehaviours(block);
                blockFirstChild.classList.add('viv-loaded');
            }
        });
    });
}

// Disable double click
document.ondblclick = function (e) {
    e.preventDefault();
};
