
interface createFiltersInput {
    form: HTMLFormElement
    onFormDataUpdate?: (formData: FormData) => void
}

export const createFilters = ({form, onFormDataUpdate}: createFiltersInput) => {
    const selects = form.querySelectorAll<HTMLSelectElement>('select');

    const submit = () => {
        const formData = new FormData(form);
        if (onFormDataUpdate) {
            onFormDataUpdate(formData);
        }
    }

    form.addEventListener('submit', (event) => {
        event.preventDefault();
        submit();
    })

    selects.forEach((select) => {
        select.addEventListener('change', () => {
            submit();
        });
    });

    return {
        submitForm: submit,
    }
}
