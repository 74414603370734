import Cookies from 'js-cookie';
import {debounce} from "../../../global/scripts/thottle";

const blockGlobalMessageBehaviour = (function () {

    const attach = function (context: HTMLElement) {
        const baseClass = 'blockGlobalMessage';
        const blocks = context.querySelectorAll<HTMLElement>(`.${baseClass}`);

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, baseClass));
    };

    const init = function (block: HTMLElement, baseClass: string) {
        const closeButton = block.querySelector(`.${baseClass}__closeButton`);
        const messageId = block.getAttribute('data-message-id') || 'default-message-id';

        closeButton && closeButton.addEventListener('click', () => {
            block.classList.add(`${baseClass}--closing`);
            Cookies.set('notification-hidden', messageId);

            setTimeout(() => {
                calculateMessageHeight();
            }, 200);
        });

        const calculateMessageHeight = () => {
            document.body.style.setProperty('--globalMessageHeight', `${block.offsetHeight}px`);
        };

        calculateMessageHeight();

        window.addEventListener('resize', debounce(() => {
            calculateMessageHeight();
        }, 100));
    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(blockGlobalMessageBehaviour);
