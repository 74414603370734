import PerfectScrollbar from "perfect-scrollbar";

behaviours.addBehaviour((function () {

    const attach = function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.scrollArea').forEach(area => init(area));
    };

    const init = function (area: HTMLElement) {
        new PerfectScrollbar(area);
    };

    return {
        attach: attach
    }
})());
