import * as handlebars from "handlebars";
import {Behaviour} from "../../../global/scripts/behaviour";
const template = require("./locations.hbs");
const data = require("./locations.json");

const locationBehaviour: Behaviour = (function () {

    const attach = () => {
        const block = document.querySelector<HTMLElement>('.locations');

        if (!block) {
            return;
        }

        const html = template({
            locations: data.locations,
            base_class: 'locations'
        });

        block.innerHTML = html;
    };

    return {
        attach: attach,
    }
})();

behaviours.addBehaviour(locationBehaviour);
