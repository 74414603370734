import {Fancybox} from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';
import {Behaviour} from '../../../global/scripts/behaviour';

/**
 * Scroll behaviour
 */
const imageViewer: Behaviour = (() => {
    const attach = (context: HTMLElement) => {
        setTimeout(() => {
            Fancybox.bind('[data-fancybox]', {
                infinite: true,
                preload: 0,
            });
        }, 1000);
    };

    return {
        attach,
    };

})();

behaviours.addBehaviour(imageViewer);
