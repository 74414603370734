import {locationsRetriever} from "./locationsRetriever";
import {createMarkers} from "./createMarkers";
import {createList} from "./createList";
import {createMap} from "./createMap";
import {createFilters} from "./createFilters";
import {LocationDto} from "./locationsSchema";
import {loadIndicators} from "./loadIndicators";

interface createLocationsMapInput {
    mapHtmlElement: HTMLElement
    locationsListElement: HTMLElement
    formElement: HTMLFormElement
    endpoint: string
    markerImage: string
    loaders: NodeListOf<HTMLElement>
}

export const createLocationMap = ({mapHtmlElement, locationsListElement, formElement, endpoint, markerImage, loaders}: createLocationsMapInput) => {
    const map = createMap(mapHtmlElement);

    const markers = createMarkers({map, markerImage});

    const list = createList({
        locationListElement: locationsListElement,
        onClickListItem: (locationId) => { markers.activateMarker(locationId) }});

    const onUpdatedLocations = (locations: LocationDto[]) => {
        markers.updateMarkersForLocations(locations);
        list.updateList(locations);
    }

    const onError: (error: any) => void = (error) => {
        console.error(error);
    }

    const indicators = loadIndicators();
    loaders.forEach((loader) => {
        indicators.addElement(loader);
    });

    const locations = locationsRetriever({
        endpoint: endpoint,
        onUpdatedLocations,
        onError,
        onLoadingStateChange: (isLoading) => {
            indicators.setLoadState(isLoading);
        },
        onUpdatedCenter: (center) => {
            markers.setCenter(center);
        }
    });

    const onFormDataUpdate = (formData: FormData) => {
        locations.setFormData(formData);
        locations.getResults();
        // const radius = formData.get('radius') || '0';
        // markers.setRadius(radius as unknown as number);
    }

    const filterForm = createFilters({
        form: formElement,
        onFormDataUpdate,
    });

    filterForm.submitForm();
}
