import {Behaviour} from './behaviour';

const topOffset = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop;
    return rect.top + scrollTop;
}

/**
 * Scroll behaviour
 */
const scrolledBehaviour: () => Behaviour = () => {
    const attach = (context: HTMLElement) => {
        if (document.querySelector('body') && !context.querySelector('.docs')) {
            initOnScroll();
        }


        document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const header = document.querySelector('.header');
                const wpadminbar = document.querySelector('.wpadminbar');

                const href = anchor.getAttribute('href');
                if (!href || href.length === 1) return;

                const element = document.querySelector<HTMLElement>(href);
                if (!element) return;

                let offset = header ? header.clientHeight : 0;
                offset = wpadminbar ? offset + wpadminbar.clientHeight : offset;

                window.scrollTo({
                    top: topOffset(element) - offset - 40,
                    behavior: "smooth"
                });
            });
        });
    };

    const initOnScroll = () => {
        document.addEventListener('scroll', (event) => onScroll());
        onScroll();
    };

    const onScroll = () => {
        if (window.scrollY > 50) {
            document.documentElement.classList.add('scrolled');
        } else {
            document.documentElement.classList.remove('scrolled');
        }
    };

    return {
        attach,
    };
};

behaviours.addBehaviour(scrolledBehaviour());
