import {computePosition, shift, Placement, autoPlacement, flip, autoUpdate} from '@floating-ui/dom';
import {debounce} from "../../../global/scripts/thottle";

const NavigationBehaviour = {
    attach: function (context: HTMLElement) {

        if (!context.querySelector<HTMLElement>('.navigation__submenu')) {
            return;
        }

        this.calculatePositions(context);

        window.addEventListener('resize', debounce(() => {
            this.calculatePositions(context);
        }, 100));

        context.querySelectorAll<HTMLLIElement>('.submenu__item--hasChildren').forEach((item) => {
            item.addEventListener('mouseenter', () => {
                NavigationBehaviour.calculatePositions(item);
            });
        });
    },

    calculatePositions: function (context: HTMLElement) {
        context.querySelectorAll<HTMLElement>('.navigation__submenu').forEach((submenu) => {
            const navItem = submenu.closest<HTMLElement>('.navigation__item, .submenu__item');

            if (!navItem) {
                return;
            }

            computePosition(navItem, submenu, {
                middleware: [autoPlacement({
                    allowedPlacements: ['bottom-start', 'bottom-end'],
                })],
            }).then(({x, y}) => {
                Object.assign(submenu.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });
            });
        });

        context.querySelectorAll<HTMLElement>('.submenu__submenu').forEach((submenu) => {
            const navItem = submenu.closest<HTMLElement>('.submenu__item');

            if (!navItem) {
                return;
            }

            computePosition(navItem, submenu, {
                placement: 'right-start',
                middleware: [flip()],
            }).then(({x, y}) => {
                Object.assign(submenu.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });
            });
        });
    }
}

behaviours.addBehaviour(NavigationBehaviour);
