
export const loadIndicators = () => {

    let elements: HTMLElement[] = [];

    const addElement = (element: HTMLElement) => {
        elements.push(element);
    }

    const setLoadState = (isLoading: boolean) => {
        elements.forEach(element => {
            if (isLoading) {
                element.classList.add('is-loading');
            } else {
                element.classList.remove('is-loading');
            }
        })
    }

    return {
        setLoadState,
        addElement,
    }
}
