import { Behaviour } from '../../../global/scripts/behaviour';

const iconMenuBehaviour : Behaviour = (() => {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.iconMenu');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block));
    };

    const init = function (block: HTMLElement) {
        const countElement = block.querySelector<HTMLElement>('.iconHolder__count')

        if (!countElement) {
            return;
        }

        document.addEventListener('update-cart-count', (e) => {
            countElement.innerHTML = e.detail.toString();
        });

        jQuery(document).ajaxComplete(function() {
            const cartCount = document.querySelector('#viv-total-cart-items');
            if (cartCount) {
                countElement.innerHTML = cartCount.innerHTML;
            }
        });
    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(iconMenuBehaviour);
