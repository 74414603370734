import { Behaviour } from '../../../global/scripts/behaviour';

const addToCartSuccessBehaviour : Behaviour = (() => {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.addToCartSuccess');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block));
    };

    const init = function (block: HTMLElement) {
        // do something
    };

    return {
        attach: attach
    }
})();
