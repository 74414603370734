import {LocationCoordinatesDto, LocationCoordinatesDtoSchema, LocationDto, LocationsDtoSchema} from "./locationsSchema";
import axios from "axios";

interface locationsRetrieverInput {
    endpoint: string,
    onUpdatedLocations?: (locations: LocationDto[]) => void
    onUpdatedCenter?: (center: LocationCoordinatesDto) => void
    onError?: (error: any) => void
    onLoadingStateChange?: (isLoading: boolean) => void
}

export const locationsRetriever = ({endpoint, onUpdatedLocations, onError, onLoadingStateChange, onUpdatedCenter}: locationsRetrieverInput) => {

    let formData: FormData | null = null;

    const setFormData = (data: FormData) => {
        formData = data;
    }

    const getResults = () => {
        if (onUpdatedLocations) {
            onLoadingStateChange && onLoadingStateChange(true);
            axios.postForm(endpoint, formData).then(function (response) {
                if (response.data) {
                    const validatedResponse = LocationsDtoSchema.validateSync(response.data);

                    if (onUpdatedCenter) {
                        onUpdatedCenter(validatedResponse.center);
                    }

                    onUpdatedLocations(validatedResponse.locations);
                } else {
                    onUpdatedLocations([]);
                }

                onLoadingStateChange && onLoadingStateChange(false);
            }).catch(function (error) {
                if (onError) {
                    onError(error);
                }
                onLoadingStateChange && onLoadingStateChange(false);
            });
        }
    }

    return {
        getResults,
        setFormData,
    }
}
