import {variations} from "./src/varations";
import {quantity} from "./src/quantity";

const productHeroBehaviour = (() => {

    const attach = (context: HTMLElement) => {
        context.querySelectorAll<HTMLElement>('.productHero').forEach((productHero) => {
            variations.attach(productHero);
            quantity.attach(productHero);
        });
    }

    return {
        attach
    }
})()

behaviours.addBehaviour(productHeroBehaviour);
