const filterBehaviour = (function () {
    const attach = function (context) {
        const blocks = context.querySelectorAll('.filter');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, context));
    };

    const init = function (block, context) {

    };

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(filterBehaviour);

