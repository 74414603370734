behaviours.addBehaviour(
    (() => {
        const attach = function () {
            document.querySelectorAll<HTMLDivElement>('body.logged-in > div').forEach((block) => {

                const className = block.getAttribute('class') ?? '';
                if (block.id && className.toLowerCase().includes('block')) {
                    const label = document.createElement('label');
                    label.innerText = `#${block.id}`;
                    label.classList.add('anchorLabel');
                    block.appendChild(label);
                    block.style.position = 'relative';
                }
            });
        };

        return {
            attach: attach,
        };
    })(),
);
