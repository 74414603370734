behaviours.addBehaviour(
    (() => {
        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const nextBlock = block.nextElementSibling;

                if (!nextBlock?.hasAttribute('data-background')) {
                    return;
                }

                const nextBackground = nextBlock.getAttribute('data-background') ?? '';
                block.setAttribute('data-next-background', nextBackground);
            });
        };

        return {
            attach: attach,
        };
    })(),
);
