import {Behaviour} from "../../../global/scripts/behaviour";

/**
 * Scroll behaviour
 */
const heroBehaviour: () => Behaviour = () => {
    const attach = (context: HTMLElement) => {
        const heroElement = context.querySelector<HTMLElement>('.hero');
        const elementAfterHero = heroElement?.nextElementSibling;

        if (!heroElement || !elementAfterHero) {
            return;
        }

        if (elementAfterHero.getAttribute('data-hero-overlap') !== 'true') {
            heroElement.classList.remove('hero--extraBottomSpacing');
        }
    };

    return {
        attach,
    };
};

behaviours.addBehaviour(heroBehaviour());
